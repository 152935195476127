import { useEffect, useRef } from "react";
import skills from "../assets/content/skills";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Skills() {
  const text = useRef(null);

  let tl = useRef(null);

  useEffect(() => {
    tl.current = gsap
      .timeline()

      .from(
        ".skills__heading",
        { x: 50, duration: 2, opacity: 0, ease: "Power3.inOut" },
        0.5
      )
      .from(".skills__card", {
        x: 100,
        opacity: 0,
        duration: 3,
        ease: "power3.in",
        stagger: 0.2,
      });

    ScrollTrigger.create({
      trigger: ".skills",
      animation: tl.current,
      //markers: true, // reference
      start: "top center+=200px",
      end: "top center+=50px",
      scrub: 2,
      toggleActions: "play complete reverse reset",
    });

    return () => {
      tl.current.kill();
      console.log("Component unmounted")
    };
  }, []);

  return skills.map((skill) => (
    <div key={skill.id} className="category__card">
      <div className="category__wrapper">
        <div
          style={{ backgroundColor: skill.color }}
          className="category__icon"
        >
          <img
            src={require(`../assets/skills/${skill.icon}.svg`).default}
            alt=""
          />
        </div>
        <div className="category__heading">
          <h3 style={{ color: skill.textColor }} ref={text}>
            {skill.category}
          </h3>
        </div>
      </div>
      <div className="skills__wrapper">
        {skill.content.map((content) => (
          <div
            key={content.id}
            style={{ backgroundColor: skill.color }}
            className="skills__card"
          >
            <div
              style={{ backgroundColor: skill.textColor }}
              className="skills__icon"
            >
              <img
                className="icon__image"
                src={
                  require(`../assets/skills/attr/${content.icon}.svg`).default
                }
                alt=""
                style={{ fill: "white !important" }}
              />
            </div>
            <div className="skills__title">
              <h2 className="title__text">{content.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
}
