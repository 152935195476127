const experience = [
    {
        id: 0,
        logo: "diridealogo",
        company: "Dirideal",
        year: "Nov 2022 to now",
        position: "Full-stack Developer",
        location: "Algiers",
        tags: [ "Mobile Development", "Dashboard", "Backend", "Web Development" ],
        link: 'https://www.dirideal.com/'
    },
    
    {
        id: 1,
        logo: "numiditlogo",
        company: "Numidit",
        year: "Jan 2022 to Sep 2022",
        position: "Full-stack Developer",
        location: "Algiers",
        tags: [ "Architecture", "Mobile Development", "Product Design", "Service Provider" ],
        link: 'https://numidit.com/'
    },
    {
        id: 2,
        logo: "freelancelogo",
        company: "Freelance",
        year: "Oct 2020 to Sept 2021",
        position: "UX Engineer",
        location: "Algiers",
        tags: [ "Graphic Design", "Product Design", "Motion Design", "Code Support"],
        link: 'https://webeit.fr/'
    },
    {
        id: 3,
        logo: "vibrandlogo",
        company: "Vibrand",
        year: "May 2020 to Feb 2021",
        position: "Full-stack Developer and Graphic Designer",
        location: "Algiers",
        tags: [ "Social Media", "Graphic Design", "Product", "Mobile Development" ],
        link: 'https://www.linkedin.com/company/vibrand-studio'

    },
    {
        id: 4,
        logo: "yassirlogo",
        company: "Yassir",
        year: "Jan 2020 to Jul 2020",
        position: "MERN Stack Developer",
        location: "Algiers",
        tags: [ "Chat App", "MERN", "Technical Support", "Prototyping"],
        link: 'https://yassir.com/'
    },
    {
        id: 5,
        logo: "kyologo",
        company: "KYŌ Conseil",
        year: "Apr 2019 to Jul 2019",
        position: "Intern Full-stack Developer",
        location: "Algiers",
        tags: [ "Laravel", "TMS", "Dashboard", "Charts" ],
        link: 'https://www.kyo-conseil.com/'
        
    },
    {
        id: 6,
        logo: "xeroxlogo",
        company: "Xerox",
        year: "Sep 2018 to Nov 2018",
        position: "Discovery Intern",
        location: "Algiers",
        tags: [ "Printing", "Logo Design", "Workflow", "Report Writing" ],
        link: 'https://www.xerox.fr/fr-fr'
    },
  ];
  
  module.exports = experience;
  