import { useEffect, useRef } from "react";
import ExperienceComponent from "../components/ExperienceComponent";
import experience from "../assets/content/experience";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Experience() {
  let tl = useRef(null);

  const ExpRef = useRef(null);
  const query = gsap.utils.selector(ExpRef);

  //CompoRefs
  const exp_card = useRef(null);
  const exp_title = useRef(null);
  const exp_img_con = useRef(null);
  const exp_img = useRef(null);
  const exp_pos = useRef(null);
  const exp_loc = useRef(null);
  const tag_title = useRef(null);
  const tag = useRef(null);

  useEffect(() => {
    //const _exp = ExpRef.current;

    tl.current = gsap
      .timeline()

      .from(query(".experience__title"), {
        x: 100,
        duration: 3.5,
        opacity: 0,
        ease: "expo.inOut",
      }, .2)
      .from(query(".experience__card"), {
        y: 200,
        opacity: 0,
        duration: 3.5,
        ease: "expo.inOut",
        stagger: .8,
      }, .2);

    ScrollTrigger.create({
      trigger: ".experience",
      animation: tl.current,
      //markers: true, // reference
      start: "top center+=200px",
      end: "top center+=50px",
      scrub: 6,
      toggleActions: "play complete reverse reset",
    });
  });

  return (
    <>
      <div className="experience" ref={ExpRef}>
        <div className="container">
          <h1 className="experience__title">Experience</h1>
          <div className="experience__container">
            {experience.map((exp, index) => (
              <ExperienceComponent
                key={index}
                logo={exp.logo}
                link={exp.link || '#'}
                company={exp.company}
                year={exp.year}
                position={exp.position}
                location={exp.location}
                tags={exp.tags}
                exp_card={exp_card}
                exp_title={exp_title}
                exp_img_con={exp_img_con}
                exp_img={exp_img}
                exp_loc={exp_loc}
                exp_pos={exp_pos}
                // exp_img={exp_img}

              />
            ))}
          </div>
        </div>
      </div>
      {/*experience.map((exp, index) => (
        <li key={index}> {exp.tags} </li>
      ))*/}
    </>
  );
}
