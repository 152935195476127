import "./style/style.scss";
import { useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Methodology from "./pages/Methodology";
import Project from "./pages/Project";
import Work from "./pages/Work";
import { WorkWrapper } from "./components/Project/WorkWrapper";
import Header from "./components/Header";
import Cursor from "./components/Cursor";
import Footer from "./components/Footer";
import {hotjar} from 'react-hotjar';

function App() {
  const _cursor = useRef(null);
  const _cursorf = useRef(null);

  useEffect(() => {
    //hotjar init
    hotjar.initialize(3114217,6);

    //page change
    hotjar.stateChange(window.location.pathname);
    
  }, [])
  

  return (
    <BrowserRouter>
      <Cursor cursorRef={_cursor} followRef={_cursorf} />
      <Header />
      <Routes>
        <Route path="/project" exact element={<Project cursorRef={_cursor} followRef={_cursorf} />} />
        <Route path="/methodology" element={<Methodology />} exact />
        <Route path="/" element={<Landing />} exact />
        <Route exact path="/project/:slug"  element={<WorkWrapper cursorRef={_cursor} followRef={_cursorf}/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
